import { breakpoints, vars, View } from '@iheartradio/web.accomplice';
import { Badge } from '@iheartradio/web.accomplice/badge';
import type { PlayerTimeControlProps } from '@iheartradio/web.accomplice/player';
import { PlayerTimeControl } from '@iheartradio/web.accomplice/player';
import { Playback } from '@iheartradio/web.playback';
import { useDebounceCallback, useMediaQuery } from 'usehooks-ts';

import { useUser } from '~app/contexts/user';
import { isPremiumUser } from '~app/utilities/user';

import { playback } from '../playback';

export function Time({
  isForAllBreakpoints = false,
}: {
  isForAllBreakpoints?: boolean;
}) {
  const isLarge = useMediaQuery(breakpoints.large);
  const player = playback.usePlayer();
  const { adBreak } = playback.useAds();
  const time = playback.useTime();
  const isPremium = isPremiumUser(useUser());
  const { isScanning, station, status } = playback.useState();
  const isLiveStation = station?.type === Playback.StationType.Live;
  const isScanStation = station?.type === Playback.StationType.Scan;
  const isPodcast = station?.type === Playback.StationType.Podcast;

  const isFullScreen = false;

  const seek = useDebounceCallback(
    (value: number) => {
      player.seek(value);
    },
    500,
    { leading: true },
  );

  // If you are an AA account, or content is a podcast - you can scrub progress bar on FSP and large+ breakpoints,
  // Otherwise (any other user type), you can not scrub,
  // Regardless, the spec is that any user can not scrub progress bar on mobile breakpoints due to the design, justifying to the use of `isLarge`.
  const position = isLiveStation && !adBreak ? 0 : time.position;

  // We only disable progress bar if it's a live station or if playback is not playing or paused
  const isDisabled =
    (isLiveStation && !adBreak) || status === Playback.Status.Buffering;

  const isInteractive = isFullScreen || isForAllBreakpoints;

  return (
    <PlayerTime
      badge={
        (
          (isLiveStation && !adBreak) ||
          (isScanStation && !isScanning && !adBreak)
        ) ?
          Playback.StationType.Live
        : undefined
      }
      duration={time.duration}
      interactive={
        (isPremium || isPodcast) && !isScanStation ?
          isInteractive || isLarge
        : false
      }
      isDisabled={isDisabled}
      isForAllBreakpoints={isForAllBreakpoints}
      onChangeEnd={value => {
        seek(value);
      }}
      position={position}
    />
  );
}

type PlayerTimeProps = PlayerTimeControlProps & {
  badge?: string;
  isForAllBreakpoints?: boolean;
};

export function PlayerTime({
  badge,
  position,
  isDisabled = false,
  duration,
  interactive,
  isForAllBreakpoints = false,
  ...props
}: PlayerTimeProps) {
  return (
    <View isHidden={{ mobile: !isForAllBreakpoints, 'container-large': false }}>
      {badge ?
        <Badge
          css={{
            backgroundColor: vars.color.brandRed,
            color: vars.color.brandWhite,
          }}
          size="small"
        >
          {badge}
        </Badge>
      : <PlayerTimeControl
          {...props}
          duration={duration}
          interactive={interactive}
          isDisabled={isDisabled}
          position={position}
        />
      }
    </View>
  );
}

PlayerTime.displayName = 'Player.Time';
