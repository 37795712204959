import { vars, View } from '@iheartradio/web.accomplice';
import { Button } from '@iheartradio/web.accomplice/button';
import { SavedPlaylist } from '@iheartradio/web.accomplice/icons';
import { MenuContent, MenuTrigger } from '@iheartradio/web.accomplice/menu';
import { PlayerTooltip } from '@iheartradio/web.accomplice/player';
import {
  addToast,
  AuthenticateCTANotification,
} from '@iheartradio/web.accomplice/toast';
import { isNull } from '@iheartradio/web.utilities';
import { useNavigate } from '@remix-run/react';
import { useCallback, useState } from 'react';
import { $path } from 'remix-routes';

import { useRegGateEvent } from '~app/analytics/use-reg-gate';
import type { CreatePlaylistSubmitCallback as CreateSubmitCallback } from '~app/components/dialogs/create-playlist-dialog';
import {
  type AddToCollectionSubmitCallback as AddSubmitCallback,
  AddToPlaylistSubMenu,
} from '~app/components/menu-items/add-to-playlist-sub-menu';
import { useUser } from '~app/contexts/user';
import {
  ADD_TO_PLAYLIST_AUTHENTICATION_MESSAGE,
  REG_GATE_TRIGGER_TYPES,
  Routes,
} from '~app/utilities/constants';
import { buildPlaylistUrl } from '~app/utilities/urls';

import { playback } from '../playback';

export function AddToPlaylist({
  isForAllBreakpoints = false,
}: {
  isForAllBreakpoints?: boolean;
}) {
  const user = useUser();
  const navigate = useNavigate();
  const { onAnalyticsRegGateOpen, onAnalyticsRegGateExit } = useRegGateEvent();
  const { station } = playback.useState();
  const metadata = playback.useMetadata();
  const [menuOpen, setMenuOpen] = useState(false);

  const handleAddSubmit = useCallback<AddSubmitCallback>(
    data => {
      if (data?.ok) {
        addToast({
          kind: 'success',
          text: `Added to ${data.collection.name}`,
          actions: [
            {
              kind: 'tertiary',
              color: 'gray',
              content: 'Go to Playlist',
              size: 'large',
              textColor: vars.color.gray600,
              onPress: () => {
                navigate(buildPlaylistUrl(data.collection));
              },
            },
          ],
        });
      }

      if (!data?.ok) {
        addToast({
          kind: 'error',
          text: "We can't add duplicate songs to a playlist.",
          title: 'These songs are already added',
        });
      }

      setMenuOpen(false);
    },
    [navigate],
  );

  const handleCreateSubmit = useCallback<CreateSubmitCallback>(
    data => {
      if (data.ok) {
        addToast({
          kind: 'success',
          text: 'Playlist created',
          actions: [
            {
              kind: 'primary',
              color: 'gray',
              content: 'Go to Playlist',
              size: 'large',
              textColor: vars.color.gray600,
              onPress: () => {
                navigate(buildPlaylistUrl(data.collection));
              },
            },
          ],
        });
      }
    },
    [navigate],
  );

  if (isNull(station) || isNull(metadata)) {
    return null;
  }

  const { trackId, id } = metadata.data;

  return (
    <View isHidden={{ mobile: !isForAllBreakpoints, large: false }}>
      <MenuTrigger
        isOpen={user?.isAnonymous ? false : menuOpen}
        onOpenChange={setMenuOpen}
      >
        <PlayerTooltip content="Add to playlist">
          <Button
            color={{ dark: 'white', light: 'gray' }}
            data-test={
              user?.isAnonymous ?
                'add-to-playlist-button'
              : 'add-to-playlist-menu-trigger'
            }
            kind="tertiary"
            onPress={() => {
              if (user?.isAnonymous) {
                addToast(
                  AuthenticateCTANotification({
                    trigger: REG_GATE_TRIGGER_TYPES.ADD_TO_PLAYLIST,
                    text: ADD_TO_PLAYLIST_AUTHENTICATION_MESSAGE,
                    size: { xsmall: 'small', medium: 'large' },
                    paths: [
                      $path(Routes.Login, { action: 'auth' }),
                      $path(Routes.SignUp),
                    ],
                    navigate,
                    onAnalyticsRegGateOpen,
                    onAnalyticsRegGateExit,
                  }),
                );
              }
            }}
            size="icon"
          >
            <SavedPlaylist size={32} />
          </Button>
        </PlayerTooltip>

        <MenuContent
          data-test="add-to-playlist-menu"
          style={{
            maxHeight: '40dvh',
          }}
        >
          <AddToPlaylistSubMenu
            onAddSubmit={handleAddSubmit}
            onCreateSubmit={handleCreateSubmit}
            tracks={[Number(trackId ?? id)]}
          />
        </MenuContent>
      </MenuTrigger>
    </View>
  );
}
